import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import block from 'bem-cn';

import Spinner from 'components/Spinner/view/desktop';
import Table from 'components/TableWithPagination/desktop/Table/Table';
import Modal from 'components/ModalProvider/Modal/Modal';
import SVG from 'components/SVG';
import InputDate from 'components/InputDate/view/desktop';
import Input from 'components/Input';

import { getBetInfo } from 'features/reports/redux/actions';

import crossSVG from 'features/reports/img/cross.svg';

import FilterBlock from './FilterBlock';

import './PlayerHistory.scss';

const b = block('player-history');

const PlayerHistory = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const { actionProcessing, betInfo } = useSelector(state => state.reports, shallowEqual);
  const slotsHistory = useSelector(state => state.reports.slotsHistory, shallowEqual);

  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [historyItemModalOpened, setHistoryItemModalOpened] = useState(false);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  const handleHistoryItemCallback = (state, betId, userId) => {
    setHistoryItemModalOpened(state);

    if (state !== false) {
      dispatch(getBetInfo({ betId, userId }));
    }
  };

  const infoRows = {
    0: [
      { type: 'date', name: 'date', value: betInfo.date },
      { name: 'provider', value: betInfo.provider },
      { name: 'prevBalance', value: betInfo.balanceBefore },
    ],
    1: [
      { name: 'player', value: betInfo.username },
      { name: 'slot', value: betInfo.gameName },
      { name: 'afterBalance', value: betInfo.balanceAfter },
    ],
    2: [
      { name: 'operation', value: betInfo.operation === '1' ? locale.statuses[1] : locale.statuses[2] },
      { name: 'summ', value: betInfo.amount },
      { name: 'refId', value: betInfo.refId },
      { name: 'id', value: betInfo.userId },
    ],
  };

  const filteredTableData = slotsHistory.data.map(obj => {
    const newObj = { ...obj };
    delete newObj[Object.keys(newObj).pop()];
    return newObj;
  });

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <FilterBlock count={count} currentPage={page} onPageClick={setPage} />
      <Table
        currentPage={page}
        onPageClick={setPage}
        onItemsOnPageChanged={onItemsOnPageChanged}
        thTdItems={slotsHistory.data}
        totalCount={slotsHistory.totalCount}
        type="player-history"
        tableItemCallback={itemData => {
          return handleHistoryItemCallback(true, itemData.id, itemData.userId);
        }}
      />
      {historyItemModalOpened && !actionProcessing && (
        <Modal
          isDesktop
          closeFunction={() => handleHistoryItemCallback(false)}
          content={
            <div className={b('item')}>
              <div className={b('item-header')}>
                <div className={b('item-title')}>{locale.betDetails}</div>
                <div onClick={() => handleHistoryItemCallback(false)}>
                  <SVG svgClassName={b('item-cross')} svgProps={{ svg: crossSVG }} />
                </div>
              </div>
              {Object.keys(infoRows).map(index => (
                <div className={b('item-row')}>
                  {infoRows[index].map(item => {
                    if (item.type === 'date') {
                      return (
                        <div className={b('item-row-data')}>
                          <div className={b('item-row-data-title')}>{locale[item.name]}</div>
                          <div className={b('item-row-data-view')}>
                            <InputDate
                              dateFormat="dd/MM/yy HH:mm:ss"
                              withoutFormatting
                              fontSize="12"
                              color="grey"
                              selected={new Date(item.value)}
                              disabled
                            />
                          </div>
                        </div>
                      );
                    }

                    return (
                      <div className={b('item-row-data')}>
                        <div className={b('item-row-data-title')}>{locale[item.name]}</div>
                        <div className={b('item-row-data-view')}>
                          <Input fontSize="12" color="grey" value={item.value} disabled></Input>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          }></Modal>
      )}
    </div>
  );
};

export default PlayerHistory;
