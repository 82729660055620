import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';
import SVG from 'components/SVG';

import ArrowSVG from './img/arrow.svg';
import PlayerSVG from './img/player.svg';

import './TableRow.scss';

const b = block('sport-bet-report-table-row-pagination');

const statusMap = {
  WON: 'Win',
  LOST: 'Lost',
  ACCEPTED: 'Accepted',
  NOT_RESULTED: 'Not resulted',
  CASHED_OUT: 'Cashed out',
};

function cleanBetOnString(str) {
  str = str.replace(/^.+?:\s*/i, '');
  return str;
}

const getFinalScore = str => {
  const regex = /(\d+:\d+)/;
  const match = str.match(regex);
  return match ? match[0] : str;
};

const DetailsItem = ({ value, isLast, status }) => {
  return (
    <div className={b('details-item-cell')}>
      <div
        className={b('details-item-cell-value', {
          win: isLast && status === 'WON',
          lost: isLast && status === 'LOST',
          accepted: isLast && status === 'ACCEPTED',
          notResulted: isLast && status === 'NOT_RESULTED',
          cashedOut: isLast && status === 'CASHED_OUT',
        })}>
        {value}
      </div>
    </div>
  );
};

const DetailsRow = ({ items, status }) => (
  <div className={b('details-item-row')}>
    {items.map(({ value }, index) => {
      const isLast = index === items.length - 1;
      return <DetailsItem key={`${value}_${index}`} value={value} isLast={isLast} status={status} />;
    })}
  </div>
);

const TableRow = ({ item, arrowOnClick }) => {
  const locale = useSelector(state => state.locale.locale);

  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpand = () => {
    setIsExpanded(prev => {
      const newState = !prev;
      if (newState) {
        arrowOnClick(item?.couponId);
      }
      return newState;
    });
  };

  return (
    <div>
      <div className={b('tr', { isExpanded })}>
        <div className={b('td')}>
          <span>{item?.couponId}</span>
        </div>
        <div className={b('td')}>
          <div className={b('user-block')}>
            <SVG svgProps={{ svg: PlayerSVG }} className={b('type-user-img')} />
            <div className={b('user-info')}>
              <span className={b('user-id')}>{item?.username}</span>
              <span className={b('user-type')}>{locale.player}</span>
            </div>
          </div>
        </div>
        <div className={b('td')}>{item?.date}</div>
        <div className={b('td')}>{item?.type}</div>
        <div className={b('td')}>{formatNumberWithLimit(item?.amount)}</div>
        <div className={b('td')}>{formatNumberWithLimit(item?.totalCoeff)}</div>
        <div className={b('td')}>{formatNumberWithLimit(item?.possibleGain)}</div>
        <div className={b('td')}>
          <div
            className={b('status', {
              win: item.status === 'WON',
              lost: item.status === 'LOST',
              accepted: item.status === 'ACCEPTED',
              notResulted: item.status === 'NOT_RESULTED',
              cashedOut: item.status === 'CASHED_OUT',
            })}>
            {statusMap[item.status] || item.status}
          </div>
          <div className={b('arrow-dropdown')} onClick={toggleExpand}>
            <SVG svgProps={{ svg: ArrowSVG }} className={b(isExpanded ? 'expanded' : '')} />
          </div>
        </div>
      </div>
      {item.details && isExpanded && (
        <div className={b('details')}>
          <div className={b('details-head')}>
            <div>{locale.events.title}</div>
            <div>{locale.events.type}</div>
            <div>{locale.events.location.country}</div>
            <div>{locale.events.tournament}</div>
            <div>{locale.events.sport}</div>
            <div>{locale.events.bet.selection}</div>
            <div>{locale.events.bet.odds}</div>
            <div>{locale.events.result.score}</div>
            <div>{locale.events.bet.outcome}</div>
          </div>
          {item.details.data.map((detailsItem, index) => (
            <div
              key={index}
              className={b('details-item', {
                [detailsItem.status.toLowerCase()]: true,
                alternate: index % 2 === 0,
              })}>
              <DetailsRow
                items={[
                  { value: detailsItem.event_name },
                  { value: detailsItem.is_live ? 'Live' : 'No Live' },
                  { value: detailsItem.country_name },
                  { value: detailsItem.tournament_name },
                  { value: detailsItem.sport_name },
                  { value: cleanBetOnString(detailsItem.outcome) },
                  { value: detailsItem.coef },
                  { value: getFinalScore(detailsItem.score) },
                  { value: statusMap[detailsItem.status] || detailsItem.status },
                ]}
                status={detailsItem.status}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    amountIn: PropTypes.number.isRequired,
    amountOut: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

DetailsRow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

DetailsItem.propTypes = {
  value: PropTypes.string.isRequired,
};

export default TableRow;
