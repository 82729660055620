/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'components/Button';
import SVG from 'components/SVG';
import Spinner from 'components/Spinner/desktop';

import { actions } from 'features/users/redux';
import dropdownSVG from 'features/users/img/dropdown.svg';

import PermissionsDetailsItems from './PermissionsDetailsItems/PermissionsDetailsItems';
import PermissionsDetailsForAgent from './PermissionsDetailsForAgent/PermissionsDetailsForAgent';

import { permissionsDetailsData } from '../../../../data';
import { userTypes } from '../../../../data';

import './ChangePermissions.scss';

const b = block('change-permissions-modal-desktop');

const ChangePermissions = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { userInfo, actionProcessing } = useSelector(state => state.users);
  const { userId } = useParams();
  const history = useHistory();

  const [permissionsForAgent, setPermissionsForAgent] = useState({
    isOpen: false,
    permissionsToChildUsers: true,
    category: '',
    enabled: true,
    isChildItem: false,
    items: [],
  });

  useEffect(() => {
    dispatch(actions.getUserInfo({ id: userId }));
  }, []);

  useEffect(() => {
    dispatch(actions.getBannedProviders({ id: userId }));
  }, [userInfo]);

  const handleSavePermissionsForAgent = () => {
    const data = {
      providers: permissionsForAgent.isChildItem
        ? permissionsForAgent.items
        : permissionsDetailsData.filter(item => item.category === permissionsForAgent.category)[0].items,
      action: permissionsForAgent.enabled ? 'remove' : 'add',
      isFullBranch: permissionsForAgent.permissionsToChildUsers,
      id: userId,
      category: permissionsForAgent.category,
    };

    dispatch(actions.setBannedProviders({ data }));
  };

  const onButtonClick = () => {
    if (permissionsForAgent.isOpen) {
      setPermissionsForAgent({
        ...permissionsForAgent,
        isOpen: false,
      });
    } else {
      history.goBack();
    }
  };

  const permissionsDetailsItems = permissionsDetailsData?.map(item => (
    <PermissionsDetailsItems
      item={item}
      userId={userId}
      userInfo={userInfo}
      permissionsForAgent={permissionsForAgent}
      setPermissionsForAgent={setPermissionsForAgent}
    />
  ));

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('form')}>
        {permissionsForAgent.isOpen ? (
          <div className={b('permission-edit-header')}>
            <SVG svgProps={{ svg: dropdownSVG }} className={b('permission-edit-icon')} onClick={onButtonClick} />
            <div>{locale.changePermissions(permissionsForAgent.category)}</div>
          </div>
        ) : (
          <>
            <div className={b('header')}>{locale.permissionsDetails}</div>
            <div className={b('user-block')}>
              <div className={b('user-block-left')}>
                <SVG svgProps={{ svg: userTypes[userInfo.role].icon }} className={b('user-block-icon')} />
                <div className={b('user-block-info')}>
                  <span>{userInfo.username}</span>
                  <div className={b('user-block-info-type', { role: userInfo.role })}>
                    {userTypes[userInfo.role].type}
                  </div>
                </div>
              </div>
              <div className={b('user-block-right')}>
                <span>ID:</span>
                <span>{userId}</span>
              </div>
              <div className={b('user-block-right')}>
                <span>{locale.balance}:</span>
                <span>{userInfo.balance}</span>
              </div>
            </div>
          </>
        )}
        <div className={b('permissions')}>
          {permissionsForAgent.isOpen ? (
            <PermissionsDetailsForAgent
              permissionsForAgent={permissionsForAgent}
              setPermissionsForAgent={setPermissionsForAgent}
            />
          ) : (
            permissionsDetailsItems
          )}
        </div>
        <div className={b('bottom')}>
          <div className={b('buttons')}>
            <div className={b('button')}>
              <Button callBack={onButtonClick} text={locale.cancel} size="low" color="full-transparent" upperCased />
            </div>
          </div>
          {permissionsForAgent.isOpen && (
            <div className={b('button')}>
              <Button callBack={handleSavePermissionsForAgent} text={locale.save} size="low" color="modalBlue" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePermissions;
