import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useCashList = item => {
  const locale = useSelector(state => state.locale.locale);
  return useMemo(
    () => [
      { data: item.operation === 0 ? locale.deposit : locale.withdrawal, isRed: true },
      { head: 'ID', data: item.id, notColumn: true },
      { data: item.createdAt },
      { head: locale.initiator, data: item.initiatorUser },
      { head: locale.from, data: item.fromUser },
      { head: locale.to, data: item.toUser },
      { head: locale.prevBalance, data: item.balanceFrom, isBalance: true },
      { head: locale.afterBalance, data: item.balanceTo },
      { head: locale.amount, data: item.amount },
    ],
    [locale, item],
  );
};

const usePlayerHistoryList = item => {
  const locale = useSelector(state => state.locale.locale);
  return useMemo(
    () => [
      { head: 'ID', data: item.id, notColumn: true },
      { data: item.date },
      { data: locale.statuses[item.status], status: item.status },
      { head: locale.player, data: item.player },
      { head: locale.provider, data: item.provider },
      { head: locale.prevBalance, data: item.prevBalance },
      { head: locale.afterBalance, data: item.afterBalance },
      { head: locale.amount, data: item.amount },
    ],
    [locale, item],
  );
};

const usePlayerHistoryListDesktop = item => {
  return useMemo(
    () => ({
      id: item.id,
      date: item.date,
      player: item.player,
      provider: item.provider,
      amount: item.amount,
      prevBalance: item.prevBalance,
      afterBalance: item.afterBalance,
      status: item.status,
    }),
    [item],
  );
};

const usePlayerDetailList = item => {
  const locale = useSelector(state => state.locale.locale);
  return useMemo(
    () => [
      { head: 'ID', data: item.id },
      { head: locale.name, data: item.name },
      { head: locale.amountIn, data: item.amountIn },
      { head: locale.amountOut, data: item.amountOut },
      { head: locale.total, data: item.total },
    ],
    [locale, item],
  );
};

const useSportBetsList = item => {
  const locale = useSelector(state => state.locale.locale);
  return useMemo(
    () => [
      { head: locale.couponId, data: item.couponId },
      { head: locale.date, data: item.date },
      { head: locale.user, data: item.user },
      { head: locale.type, data: item.type },
      { head: locale.amount, data: item.amount },
      { head: locale.balanceBefore, data: item.balanceBefore },
      { head: locale.balanceAfter, data: item.balanceAfter },
    ],
    [locale, item],
  );
};

export { useCashList, useSportBetsList, usePlayerHistoryList, usePlayerDetailList, usePlayerHistoryListDesktop };
