import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';
import DaysFilter from 'features/reports/view/desktop/DaysFilter';

import { actions } from 'features/finance';

import './FilterBlock.scss';

const b = block('filter-block-my-debt');

const FilterBlock = ({ count, currentPage, isMobile, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'week')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }
    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  useEffect(() => {
    handleSearch();
  }, [count, currentPage]);

  const onClickSearch = () => {
    onPageClick(0);
    handleSearch();
  };

  const handleSearch = () => {
    const requestedData = {
      count,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getFinanceHistory(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, []);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('wrapper')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
        <div className={b('or')}>or</div>
        <div className={b('date')}>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateFrom}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.beginDate} onChange={onChangeBeginDate} />
            </div>
          </div>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateTo}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.endDate} onChange={onChangeEndDate} />
            </div>
          </div>
        </div>
        <div className={b('button')}>
          <div className={b('title')} style={{ color: 'transparent' }}>
            transparent placeholder
          </div>
          <Button size="low" callBack={() => onClickSearch()} text={locale.acceptFilter} />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
