const defaultTemplate = `Request execution failed. Error code: <status>.<break> Error message: <message>`;

// didn't use an object in args in order to not get error when destructuring "null"
/**
 *
 * @param {*} locale локаль с ошибками(errorStatusMessage)
 * @param {*} response объект-респонс полученный от апи
 * @returns текст ошибки, форматированный под шаблон defaultTemplate
 */
const getErrorMessage = (locale = {}, response = {}) => {
  try {
    if (locale?.[response?.codeStatus]) return locale[response?.codeStatus];

    const isDefaultMessageCorrect =
      locale?.defaultMessage?.includes('<status>') &&
      locale?.defaultMessage?.includes('<break>') &&
      locale?.defaultMessage?.includes('<message>');

    const template = isDefaultMessageCorrect ? locale.defaultMessage : defaultTemplate;
    const withMessage = Boolean(response?.errorMessage);
    const status = response?.codeStatus ?? response?.status ?? 'None';

    if (!withMessage) {
      return template.replace('<status>', status).split('<break>')?.[0];
    }

    const message = response?.errorMessage?.toString() || '';
    const correctMessage = message.length > 90 ? message.substring(0, 90) : message;

    return template.replace('<status>', status).replace('<message>', correctMessage).replace('<break>', '');
  } catch (error) {
    console.error(`ACHTUNG, getErrorMessage failed: ${error}`);
    return `getErrorMessage failed`;
  }
};

export { getErrorMessage };
