import React from 'react';
import block from 'bem-cn';

import './TableHead.scss';

const b = block('sport-bet-table-head-pagination');

const TableHead = ({ items, locale }) => {
  return (
    <div className={b()}>
      {items
        ?.filter(el => el !== 'userId' && el !== 'currency')
        .map(el => (
          <div key={el} className={b('table-head')}>
            {locale[el]}
          </div>
        ))}
    </div>
  );
};

export default TableHead;
