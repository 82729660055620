export const errorStatusMessage = {
  defaultMessage: 'Error en la ejecución de la solicitud. Código de error: <status>.<break> Mensaje de error: <message>',
  // auth
  1: 'Usuario no autorizado.',
  2: 'Correo electrónico incorrecto.',
  3: 'Tal usuario no existe',
  4: 'Contraseña no corregida',
  5: 'El usuario está baneado.',
  6: 'Clave de verificación no válida.',
  7: 'Clave de verificación caducada.',
  8: 'El usuario ya pasó la verificación.',
  9: 'Solicitud de recuperación no encontrada.',
  10: 'La solicitud de recuperación ha caducado.',
  11: 'Solicitud de recuperación no encontrada.',
  14: 'Número de cambios de tarifa por mes excedidos',
  15: 'Número de teléfono no válido.',
  101: 'Solicitud de recuperación no encontrada.',
  102: 'Solicitud de recuperación no encontrada.',
  103: 'La transacción no se puede completar.',
  104: 'Error al mostrar el estado de retiro de fondos.',
  105: 'No puedes retirar fondos. Las condiciones del programa de bonificación no se cumplen. Aplicar al chat en línea.',
  106: 'La suma de la salida es menor que el mínimo.',
  // Site
  201: 'No puedo obtener una página plana.',
  202: 'No se puede obtener la lista de páginas planas.',
  // Money
  501: 'Saldo insuficiente.',
  502: 'El usuario no existe.',
  // bet
  600: 'No se puede realizar la apuesta.',
  601: 'No se encuentra la apuesta.',
  602: 'La apuesta ya está comprada.',
  603: 'El evento ha terminado.',
  605: 'No puedes apostar. Saldo insuficiente',
  606: 'No puedes apostar. El porcentaje de bonificación no está permitido.',
  611: 'No se encuentra la apuesta.',
  612: 'Evento no encontrado.',
  613: 'Evento ya iniciado.',
  614: 'Se cambiaron los coefs.',
  615: 'Se cambiaron los tamaños de \'Hándicaps\'.',
  616: 'Error al guardar+.',
  617: 'La apuesta está bloqueada por la casa de apuestas.',
  618: 'Evento ya detenido.',
  619: 'El monto de la apuesta es mayor que el máximo.',
  620: 'Información sobre el autor de la apuesta no encontrada.',
  621: 'Información sobre el contenido de la apuesta no encontrada.',
  622: 'No hay dinero en la cuenta del jugador.',
  623: 'Se detuvo la recepción de apuestas en el evento.',
  624: 'Recepción de apuesta en la apuesta variante detenida.',
  625: 'La apuesta está vacía.',
  626: 'Número incorrecto de ordinario.',
  627: 'En el sistema express o, el mismo evento se usa más de una vez.',
  628: 'Eventos imposibles.',
  629: 'No se ha superado el límite de la apuesta máxima en una línea habitual.',
  630: 'No se ha superado el límite de la apuesta máxima en una línea en vivo.',
  631: 'No se ha superado el límite de la cantidad de todas las apuestas realizadas en la línea habitual.',
  632: 'No se ha superado el límite en la cantidad de todas las apuestas realizadas en la línea en vivo.',
  633: 'No se ha superado el límite del pago máximo en una línea habitual.',
  634: 'No se ha superado el límite del pago máximo en una línea activa.',
  635: 'No se ha superado el límite de la apuesta máxima en una línea habitual.',
  636: 'No se ha superado el límite de la apuesta máxima en una línea en vivo.',
  637: 'No se ha superado el límite de la cantidad de todas las apuestas realizadas en la línea habitual.',
  638: 'No se ha superado el límite de la cantidad de todas las apuestas realizadas en la línea en vivo.',
  639: 'No se ha superado el límite del pago máximo en una línea habitual.',
  640: 'No se ha superado el límite del pago máximo en una línea activa.',
  641: 'No se ha superado el límite de la apuesta máxima en una línea habitual.',
  642: 'No se ha superado el límite de la apuesta máxima en una línea en vivo.',
  643: 'No se ha superado el límite de la cantidad de todas las apuestas realizadas en la línea habitual.',
  644: 'No se ha superado el límite en la cantidad de todas las apuestas realizadas en la línea en vivo.',
  645: 'No se ha superado el límite del pago máximo en una línea habitual.',
  646: 'No se ha superado el límite del pago máximo en una línea activa.',
  647: 'No se ha superado el límite de la apuesta máxima en una línea habitual.',
  648: 'No se ha superado el límite de la apuesta máxima en una línea en vivo.',
  649: 'No se ha superado el límite de la cantidad de todas las apuestas realizadas en la línea habitual.',
  650: 'No se ha superado el límite en la cantidad de todas las apuestas realizadas en la línea en vivo.',
  651: 'No se ha superado el límite del pago máximo en una línea habitual.',
  652: 'No se ha superado el límite del pago máximo en una línea activa.',
  653: 'No se ha superado el límite de la apuesta máxima en una línea habitual.',
  654: 'No se ha superado el límite de la apuesta máxima en una línea en vivo.',
  655: 'No se ha superado el límite de la cantidad de todas las apuestas realizadas en la línea habitual.',
  656: 'No se ha superado el límite en la cantidad de todas las apuestas realizadas en la línea en vivo.',
  657: 'No se ha superado el límite del pago máximo en una línea habitual.',
  658: 'No se ha superado el límite del pago máximo en una línea activa.',
  659: 'No se ha superado el límite del pago máximo por apuesta en apuesta en una línea habitual.',
  660: 'No se ha superado el límite del pago máximo por apuesta en evento de apuesta en una línea en vivo.',
  661: 'No se ha superado el límite del pago máximo por apuesta en evento en una línea habitual.',
  662: 'No se ha superado el límite del pago máximo por apuesta en evento en una línea en vivo.',
  663: 'No se ha superado el límite del pago máximo por apuesta en torneo en una línea habitual.',
  664: 'No se ha superado el límite del pago máximo por apuesta en torneo en una línea en vivo.',
  665: 'No se ha superado el límite de pago máximo por apuesta por usuario en una línea habitual.',
  666: 'No se ha superado el límite de pago máximo por apuesta por usuario en una línea en vivo.',
  667: 'No se ha superado el límite del pago máximo por apuesta en deporte por parte del usuario en una línea habitual.',
  668: 'No se ha superado el límite del pago máximo por apuesta en deportes por parte del usuario en una línea en vivo.',
  669: 'Usuario no encontrado.',
  670: 'No se encuentra el punto.',
  671: 'No se encuentra el torneo.',
  672: 'No se encuentra el contenido de la apuesta.',
  673: 'La apuesta es en vivo, el evento no es en vivo',
  674: 'El evento es en vivo, la apuesta no es en vivo',
  675: 'Coef de dinero es malo.',
  676: 'Un usuario hizo más de una apuesta en una unidad de tiempo.',
  677: 'La apuesta mínima es mayor que la apuesta máxima.',
  678: 'Top-express no encontrado.',
  679: 'Se excedió el monto máximo legal de pago por apuesta..',
  680: 'El evento es solo en el expreso.',
  681: 'El evento es solo en la apuesta simple.',
  682: 'Mal número de ordinars en el express (triple express).',
  683: 'Número incorrecto de ordinars en el expreso (expreso cuádruple).',
  684: 'Número incorrecto de ordinars en el expreso (expreso quinario).',
  685: 'Número incorrecto de ordinars en el expreso (expreso senario).',
  686: 'Número incorrecto de ordinars en el expreso (expreso que contiene siete apuestas).',
  687: 'Número incorrecto de ordinars en el expreso (expreso que contiene ocho apuestas).',
  688: 'Número incorrecto de ordinars en el expreso (expreso que contiene nueve apuestas).',
  689: 'Se excedió el tamaño del coef máximo en el express.',
  690: 'El monto de la apuesta excede el límite actual del punto.',
  691: 'El límite para el número de esta apuesta para este usuario.',
  692: 'Tipo de software inaceptable, este software no puede apostar',
  693: 'Moneda inaceptable.',
  694: 'Se excedió el límite de colocación de apuestas.',
  999: 'Error de Betroute.',
  // Promocode
  2200: 'No se encontró el código de promoción.',
  2201: 'El código de promoción ha caducado.',
  2202: 'Ya se ha activado el código promocional individual.',
  2203: 'Ya has activado este código de promoción.',
  2204: 'Está intentando activar el código promocional de otro socio.',
  2205: 'Está intentando activar su propio código promocional.',
  2206: 'Tipo de código promocional desconocido.',
  2207: 'Ya has activado este porcentaje de código promocional.',
  2208: 'Ya has activado el código de promoción.',
  2209: 'El usuario ya canceló el código promocional.',
  2210: 'El usuario ya llenó el código promocional anteriormente.',
  2211: 'El usuario no es miembro del programa de bonificación.',
  2212: 'Promoode fue bloqueado por el administrador',
  2213: 'El usuario tiene retiros después de la activación del código de promoción.',
  2214: 'El usuario no está satisfecho con los términos del programa de bonificación.',
};
