import React, { useCallback } from 'react';
import { block } from 'bem-cn';
import { useSelector } from 'react-redux';

import Input from 'components/Input';
import SVG from 'components/SVG';
import useTree from 'hooks/useTree';

import searchSvg from './img/search.svg';

import './UsersFilterBlock.scss';

const b = block('users-filter-block');
const UsersFilterBlock = ({
  filterValue,
  changeFilterValue,
  callBack = null,
  actionProcessing,
  withoutSearchIcon = false,
  placeholder = null,
}) => {
  const locale = useSelector(state => state.locale.locale);
  const { getTreeById } = useTree();

  const activeCallback = () => {
    if (callBack) return callBack();
    return getTreeById(filterValue || '');
  };

  const onButtonClick = useCallback(
    props => {
      if (props) {
        switch (props.type) {
          case 'keydown':
            if (props?.event.key === 'Enter') activeCallback();
            break;
          default:
            return null;
        }
      }
    },
    [filterValue],
  );

  return (
    <div className={b()}>
      <div className={b('input')}>
        <Input
          onKeyDown={event => onButtonClick({ event, type: 'keydown' })}
          value={filterValue}
          callBack={changeFilterValue}
          placeholder={placeholder ?? `${locale.search} ${locale.user}`}
        />
      </div>
      {!withoutSearchIcon && (
        <button disabled={actionProcessing || !filterValue.length} className={b('icon-wrapper')} onClick={callBack}>
          <SVG svgProps={{ svg: searchSvg }} className={b('icon').toString()} />
        </button>
      )}
    </div>
  );
};

export default UsersFilterBlock;
