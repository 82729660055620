import React, { useMemo } from 'react';
import block from 'bem-cn';

import { usePlayerHistoryListDesktop } from 'features/reports/hooks/useLists';

import './TableHead.scss';

const TableHead = ({ items, locale, type }) => {
  const b = block('table-head-pagination');

  const getList = useMemo(() => {
    switch (type) {
      case 'player-history':
        return usePlayerHistoryListDesktop;
      default:
        return () => [];
    }
  }, [type]);

  const list = type ? Object.keys(getList(items)) : items;

  return (
    <div className={b()}>
      {list?.map(el => (
        <div key={el} className={b('table-head')}>
          {locale[el]}
        </div>
      ))}
    </div>
  );
};

export default TableHead;
