/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import SVG from 'components/SVG';
import Switcher from 'components/Switcher';

import { actions } from 'features/users/redux';
import detailsSVG from 'features/users/img/details.svg';
import dropdownSVG from 'features/users/img/dropdown.svg';

import { permissionsDetailsData } from '../../../../../data';

import './PermissionsDetailsItems.scss';

const b = block('permissions-details-items-desktop ');

const PermissionsDetailsItems = ({ item, userId, userInfo, permissionsForAgent, setPermissionsForAgent }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { bannedProviders } = useSelector(state => state.users);

  const [categoryOpen, setCategoryOpen] = useState(
    permissionsDetailsData.reduce((acc, curr) => ((acc[curr.category] = false), acc), {}),
  );

  const checkIsEqual = array => {
    const filteredArray = bannedProviders?.filter(item => array.includes(item));

    return array.length === filteredArray?.length;
  };

  const findCategory = item =>
    permissionsDetailsData.filter(categoryItem => categoryItem.items.includes(item))[0].category;

  const handleSetProviderState = data => dispatch(actions.setBannedProviders({ data }));

  return (
    <div className={b()} key={item.category}>
      <div className={b('item')}>
        <div className={b('item-container')}>
          <SVG
            onClick={() => setCategoryOpen({ ...categoryOpen, [item.category]: !categoryOpen[item.category] })}
            containerClassName={b('item-dropdown')}
            svgProps={{ svg: dropdownSVG }}
          />
          <div className={b('item-title')}>{locale[item.category]}</div>
        </div>
        <div className={b('item-container')}>
          <div className={b('item-switcher')}>
            <Switcher
              isActive={!checkIsEqual(item.items)}
              onSwitch={() =>
                handleSetProviderState({
                  providers: item.items,
                  action: checkIsEqual(item.items) ? 'remove' : 'add',
                  isFullBranch: true,
                  id: userId,
                  category: item.category,
                })
              }
            />
          </div>
          <>
            {userInfo.role !== '0' && (
              <SVG
                onClick={() =>
                  setPermissionsForAgent({
                    ...permissionsForAgent,
                    isOpen: true,
                    category: item.category,
                    enabled: !checkIsEqual(item.items),
                    items: item.items,
                    isChildItem: false,
                  })
                }
                containerClassName={b('item-details')}
                svgProps={{ svg: detailsSVG }}
              />
            )}
          </>
        </div>
      </div>
      {categoryOpen[item.category] &&
        item.items.map(item => {
          return (
            <div key={item} className={b('item', { inner: true })}>
              <div className={b('item-container')}>
                <div className={b('item-title', { inner: true })}>{item}</div>
              </div>
              <div className={b('item-container')}>
                <div className={b('item-switcher')}>
                  <Switcher
                    isActive={!bannedProviders.includes(item)}
                    onSwitch={() =>
                      handleSetProviderState({
                        providers: [item],
                        action: bannedProviders.includes(item) ? 'remove' : 'add',
                        isFullBranch: true,
                        id: userId,
                        category: findCategory(item),
                      })
                    }
                  />
                </div>
                {userInfo.role !== '0' && (
                  <SVG
                    onClick={() =>
                      setPermissionsForAgent({
                        ...permissionsForAgent,
                        isOpen: true,
                        category: item,
                        enabled: !bannedProviders?.includes(item),
                        items: [item],
                        isChildItem: true,
                      })
                    }
                    containerClassName={b('item-details')}
                    svgProps={{ svg: detailsSVG }}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PermissionsDetailsItems;
