/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import block from 'bem-cn';

import Switcher from 'components/Switcher';

import './PermissionsDetailsForAgent.scss';

const b = block('permissions-details-for-agent-desktop');

const PermissionsDetailsForAgent = ({ permissionsForAgent, setPermissionsForAgent }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  return (
    <div className={b()}>
      <div className={b('header-info')}>
        <div className={b('header-info-title', { fullWidth: true })}>
          {locale.showTheSelectedCategoryForUser}
          <div className={b('header-info-switcher', { noGap: true })}>
            <Switcher
              isActive={permissionsForAgent.enabled}
              onSwitch={() =>
                setPermissionsForAgent({
                  ...permissionsForAgent,
                  enabled: !permissionsForAgent.enabled,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className={b('header-info')}>
        <div className={b('header-info-title', { fullWidth: true })}>
          {locale.copyPermissions}
          <div className={b('header-info-switcher', { noGap: true })}>
            <Switcher
              isActive={permissionsForAgent.permissionsToChildUsers}
              onSwitch={() =>
                setPermissionsForAgent({
                  ...permissionsForAgent,
                  permissionsToChildUsers: !permissionsForAgent.permissionsToChildUsers,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionsDetailsForAgent;
