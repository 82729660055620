import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changePassword: 'Cambia la contraseña',
  logout: 'Cerrar sesión',
  transferOfFunds: 'Transferencia de fondos',
  withdrawalOfFunds: 'Retiro de fondos',

  agentPlatfrom: 'Plataforma de agentes',
  email: 'Correo electrónico',
  password: 'Clave',
  login: 'Acceso',
  other: 'Los demás',
  username: 'Nombre de usuario',
  user: 'Usuario',
  language: 'Idioma',
  confirmNewPassword: 'Confirmar nueva contraseña',
  changeMyPassword: 'Cambiar mi contraseña',
  statuses: {
    1: 'Victoria',
    2: 'Perdido',
  },

  generalReport: 'Informe General',
  depositsAndWithdrawals: 'Depósitos y retiros',
  playerAndAgentDetail: 'Detalle por Jugador y Agente',
  agents: 'Agentes',
  agent: 'Agente',
  owner: 'propietario',
  confirmPassword: 'Confirmar contraseña',

  emtretenimiento: 'Somos emtretenimiento',

  users: 'Usuarios',
  seeAll: 'Ver todo',
  seeAgents: 'Ver agentes',
  seePlayers: 'Ver jugadores',
  createAgent: 'Crear agente',
  createPlayer: 'Crear jugador',
  reports: 'Informes',
  financialReports: 'Reportes financieros',
  general: 'General',
  cashDepositsWithdrawals: 'Efectivo (depósitos y retiros)',
  playerReports: 'Informes de jugadores',
  playerHistory: 'Historial del jugador',
  detailByPlayer: 'Detalle por jugador',
  sportsBets: 'Apuestas deportivas',
  finance: 'Finanzas',
  myDebtAccount: 'Mi cuenta de deuda',
  accountBalance: 'Saldo de la cuenta',
  howToStart: 'Cómo empezar',

  search: 'Búsqueda',
  newAgent: 'Nuevo agente',
  newPlayer: 'Nuevo jugador',
  player: 'Jugador',

  allUsers: 'Todos los usuarios',
  players: 'jugadores',
  disabled: 'Desactivado',
  balance: 'Saldo',
  operations: 'Operaciones',
  actions: 'Comportamiento',
  records: 'Registros',
  recordsOnPage: 'Grabar en la página',
  elementsOnPage: 'Elementos en la página',

  withdrawal: 'Retiro',
  deposit: 'Depósito',
  topup: 'Depósito',
  cancel: 'Cancelar',
  save: 'Crear',

  name: 'Nombre',
  filter: 'Filtro',
  acceptFilter: 'Aceptar filtro',

  surname: 'Apellido',
  withdrawalAllowed: 'Permitir <span>retiros</span> para sus agentes y jugadores',
  depositAllowed: 'Permitir <span>depósitos</span> para sus agentes y jugadores',
  createSuccess: 'Éxito',
  amount: 'Monto',

  disable: 'Deshabilitar',
  edit: 'Editar',
  userEdit: 'Editar',
  informations: 'Informaciones',
  id: 'ID',
  accept: 'Aceptar',
  disableUser: 'Deshabilitar usuario',
  acceptDisableUser: 'Sí, deshabilitar usuario',
  acceptEnableUser: 'Sí, habilitar usuario',
  disableDescription: 'Está seguro de que desea deshabilitar el reproductor <br/> con nombre de usuario',
  enableDescription: 'Está seguro de que desea habilitar el reproductor <br/> con nombre de usuario',

  modifyAgent: 'Modificar agente',
  information: 'Información',
  hierachy: 'jerarquía',
  currency: 'Divisa',
  creationDate: 'Fecha de creación',
  modificationDate: 'Fecha de modificación',
  enable: 'Habilitar',
  enableUser: 'Habilitar usuario',
  close: 'Cerrar',
  noResults: 'No se han encontrado resultados',

  today: 'Este Dia',
  yesterday: 'El dia de ayer',
  lastWeek: 'La semana pasada',
  lastMonth: 'El mes pasado',
  dateFrom: 'Fecha de',
  dateTo: 'Fecha hasta',
  local: 'Local',
  utc: 'UTC',
  dateUntil: 'Fecha hasta',
  help: 'Ayuda',
  show: 'Espectáculo',
  directOnly: 'Solo directo',
  timeZoneMsg:
    'La zona horaria local tiene en cuenta la fecha y la hora que ha configurado en su dispositivo. La zona horaria UTC 00:00 es un estándar de tiempo universal. Si selecciona la opción "Local", se tendrá en cuenta la hora de su país, y si selecciona la opción "UTC", se tendrá en cuenta la hora estándar universal.',
  10: '10',
  total_bet_count: 'Recuento total de apuestas',
  total_bet: 'Apuesta total',
  total_won: 'Total ganado',
  betCount: 'Recuento de apuestas',
  betAmount: 'monto de la apuesta',
  winAmount: 'Cantidad ganada',
  provider: 'Proveedor',

  searchIn: 'Busca en',
  higher: 'Más alto',
  transaction: 'Transacción',
  date: 'Fecha',
  operation: 'Operación',
  transitionType: 'Tipo de transición',
  agentInitiator: 'Agente iniciador',
  initiator: 'Iniciador',
  destinationAgent: 'Agente de destino',

  reportsGeneral: 'Informes generales',
  playerReportsGeneral: 'Informes de jugadores generales',
  sportBets: 'Apuestas deportivas',
  amountIn: 'Cantidad en',
  amountOut: 'importe',
  total: 'Total',
  byProvider: 'Por proveedor',
  myOperations: 'Mis operaciones',
  result: 'Resultado',

  gameType: 'Tipo de juego',
  statusBet: 'Apuesta de estado',
  type: 'Tipo',
  win: 'Victoria',
  bet: 'Apuesta',
  refund: 'Reembolso',
  liveCasino: 'casino en vivo',
  fiable: 'fiable',
  all: 'Todos',
  slots: 'Tragamonedas',
  status: 'Estado',
  prevBalance: 'Saldo antes',
  balanceAfter: 'Saldo después',
  prevBefore: 'Saldo ante',
  afterBalance: 'Saldo después',
  userId: 'Identificación de usuario',
  couponId: 'identificación del cupón',
  wait: 'Esperar',
  return: 'Devolver',
  loss: 'Pérdida',
  sold: 'Vendido',
  isExpress: 'es expreso',
  createdAt: 'Creado en',
  totalCoeff: 'coeficiente total',
  fromUser: 'Del usuario',
  toUser: 'Al usuario',
  from: 'Del',
  to: 'Al',

  subagents: 'Subagentes',
  newPassword: 'Nueva contraseña',
  oldPassword: 'Contraseña antigua',
  repeatPassword: 'Repita la contraseña',
  passwordsDontMatch: '¡Las contraseñas no coinciden!',

  total_income: 'Ingreso total',
  incomeAmount: 'Cantidad de ingresos',

  enterTextInLowercaseOnly: 'Introducir texto sólo en minúsculas',
  lastMovements: 'Últimos movimientos',
  parent: 'Padre',
  userTree: 'Árbol de usuarios',
  currentUser: 'usuario actual',
  playersCount: 'Los jugadores cuentan',

  changePermissions: type => `Edición de permisos: ${type} `,
  changePermissionsCasino: 'Permission edit: Casino',
  changePermissionLiveCasino: 'Permission edit: Live Casino',
  changePermissionSport: 'Permission edit: Sport',
  showTheSelectedCategoryForUser: 'Mostrar la categoría seleccionada para el usuario',
  permissionsDetails: 'Detalles de permisos',
  casino: 'Casino',
  sport: 'Deportivas',
  permissions: 'Permisos',
  copyPermissions: 'Copiar permisos a usuarios secundarios',

  paymentStatuses: {
    0: 'Depósito',
    1: 'Retiro',
  },
  notification: 'Notificación',
  depositMessage: (name, role) => `El ${role} ${name} ha realizado un depósito a su saldo`,
  withdrawalMessage: (name, role) => `El ${role} ${name} ha retirado dinero de su saldo`,

  new: 'Nuevo',
  loadOperations: 'Cargar operaciones',
  betDetails: 'Detalles de la apuesta',
  slot: 'Ranura',
  summ: 'Suma',
  refId: 'Ref. id',
  betId: 'ID de apuesta',

  events: {
    title: 'Evento',
    type: 'Tipo de evento',
    location: {
      country: 'País/Región',
    },
    tournament: 'Torneo',
    sport: 'Deporte',
    bet: {
      selection: 'Apuesta a',
      odds: 'Cuota',
      outcome: 'Resultado',
    },
    result: {
      score: 'Marcador final',
    },
  },
  possibleGain: 'Posible ganancia',
};
