import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'components/Select/desktop';
import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';
import Input from 'components/Input';
import CheckBox from 'components/CheckBox';

import DaysFilter from 'features/reports/view/desktop/DaysFilter';
import useTree from 'hooks/useTree';

import { searchInItems } from 'features/reports/data';

import { actions } from 'features/reports/redux';

import './FilterBlock.scss';

const b = block('filter-block-cash');

const FilterBlock = ({ count, currentPage, isMobile, onPageClick, setIsOnlyTotal, isOnlyTotal }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const { getTreeById } = useTree();
  const username = useSelector(state => state.reports.agentName);

  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'week')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    role: searchInItems[0],
    user: username,
  });
  const [isDirectOnly, setIsDirectOnly] = useState(false);
  const [isHigher, setIsHigher] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [isWithdrawal, setIsWithdrawal] = useState(false);

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }
    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const handleSearchInChange = value => {
    const searchInItem = searchInItems.find(item => item.value === value);
    setFilters({ ...filters, role: searchInItem });
  };

  const handleUserChange = useCallback(
    e => {
      setFilters({ ...filters, [e.currentTarget.name]: e.currentTarget.value.toLowerCase() });
    },
    [filters],
  );

  const onClickSearch = withoutZeroPage => {
    if (isOnlyTotal) {
      getTreeById(filters.user || '').then(upperAgent => upperAgent && handleSearch(upperAgent, true));
    } else {
      setIsOnlyTotal(true);
    }
    if (!withoutZeroPage) onPageClick(0);
  };

  useEffect(() => {
    return () => {
      dispatch(actions.setPlayerUsername('', searchInItems[0].value));
    };
  }, [dispatch]);

  const handleSearch = useCallback(
    (upperAgent, isTotal = isOnlyTotal) => {
      const requestedData = {
        isDirectOnly,
        isHigher,
        isDeposit,
        isWithdrawal,
        role: filters.role.value,
        username: filters.user,
        count,
        upperAgentUsername: upperAgent && upperAgent.username,
        page: currentPage,
        ...date,
      };

      dispatch(actions.getPaymentHistory(requestedData, isTotal));
    },
    [
      count,
      currentPage,
      date,
      dispatch,
      filters.role.value,
      filters.user,
      isDeposit,
      isDirectOnly,
      isHigher,
      isWithdrawal,
      isOnlyTotal,
    ],
  );

  useEffect(() => {
    getTreeById(filters.user || '').then(upperAgent => upperAgent && handleSearch(upperAgent));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, currentPage, isOnlyTotal]);

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, [date]);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('top')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} isMobile={isMobile} />
        <div className={b('or')}>or</div>
        <div className={b('date')}>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateFrom}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.beginDate} onChange={onChangeBeginDate} />
            </div>
          </div>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateTo}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.endDate} onChange={onChangeEndDate} />
            </div>
          </div>
        </div>
      </div>

      <div className={b('bottom')}>
        <div className={b('inputs')}>
          <div className={b('input')}>
            <div className={b('title')}>{locale.searchIn}</div>
            <Select name="role" items={searchInItems} activeItem={filters.role} onChange={handleSearchInChange} />
          </div>
          <div className={b('input')}>
            <div className={b('title')}>{locale.agent}</div>
            <Input size="low" value={filters.user} callBack={handleUserChange} placeholder={locale.agent} name="user" />
          </div>
        </div>
        <div className={b('checkboxes')}>
          <div className={b('checkbox')}>
            <CheckBox checked={isDirectOnly} onChange={() => setIsDirectOnly(!isDirectOnly)} name="isDirectOnly" />
            <p className={b('checkbox-title')}>{locale.directOnly}</p>
          </div>
          <div className={b('checkbox')}>
            <CheckBox checked={isHigher} onChange={() => setIsHigher(!isHigher)} name="isHigher" />
            <p className={b('checkbox-title')}>{locale.higher}</p>
          </div>
          <div className={b('checkbox')}>
            <CheckBox checked={isDeposit} onChange={() => setIsDeposit(!isDeposit)} name="isDeposit" />
            <p className={b('checkbox-title')}>{locale.deposit}</p>
          </div>
          <div className={b('checkbox')}>
            <CheckBox checked={isWithdrawal} onChange={() => setIsWithdrawal(!isWithdrawal)} name="isWithdrawal" />
            <p className={b('checkbox-title')}>{locale.withdrawal}</p>
          </div>
        </div>
        <div className={b('button')}>
          <Button size="low" callBack={() => onClickSearch()} text={locale.acceptFilter} />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
