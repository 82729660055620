export const getTitlePageFromRoute = [
  {
    route: '/users/',
    title: 'users',
  },
  {
    route: '/reports/financial-reports/general',
    title: 'generalReport',
    withArrow: true,
  },
  {
    route: '/reports/financial-reports/cash',
    title: 'depositsAndWithdrawals',
    withArrow: true,
  },
  {
    route: '/reports/player-reports/player-history',
    title: 'playerHistory',
    withArrow: true,
  },
  {
    route: '/reports/player-reports/detail-by-player',
    title: 'playerAndAgentDetail',
    withArrow: true,
  },
  {
    route: '/reports/player-reports/sport-bets',
    title: 'sportBets',
    withArrow: true,
  },
  {
    route: '/finance/my-debt-account',
    title: 'myDebtAccount',
    withArrow: true,
  },
  {
    route: '/finance/how-to-start',
    title: 'howToStart',
    exception: '/finance/how-to-start/flat',
  },

  {
    route: '/user/create-player',
    title: 'createPlayer',
    userPage: true,
  },
  {
    route: '/user/create-agent',
    title: 'createAgent',
    userPage: true,
  },
  {
    route: '/user/deposit',
    title: 'deposit',
    userPage: true,
    withArrow: true,
  },
  {
    route: '/user/withdrawal',
    title: 'withdrawal',
    userPage: true,
    withArrow: true,
  },
  {
    route: '/user/enable',
    title: 'enableUser',
    userPage: true,
    withArrow: true,
  },
  {
    route: '/user/disable',
    title: 'disableUser',
    userPage: true,
    withArrow: true,
  },
  {
    route: '/user/change-password',
    title: 'changePassword',
    userPage: true,
    withArrow: true,
  },
  {
    route: '/user/edit',
    title: 'userEdit',
    userPage: true,
    withArrow: true,
  },
  {
    route: '/user/permissionsDetails',
    title: 'permissionsDetails',
    userPage: true,
    withArrow: true,
  },
  {
    route: '/user/info',
    title: 'information',
    userPage: true,
    withArrow: true,
  },
];
