import EnPNG from './img/en.png';
import EsPNG from './img/es.png';
import ChPNG from './img/ch.png';

export const languages = {
  ES: 'es-es',
  EN: 'en-US',
  CN: 'zh-CN',
};

export const languagesWithIcons = {
  'es-es': { lang: languages.ES, icon: EsPNG, text: 'Español' },
  'en-US': { lang: languages.EN, icon: EnPNG, text: 'English' },
  'zh-CN': { lang: languages.CN, icon: ChPNG, text: '中国人' },
};

export const shortLanguages = {
  'es-es': 'es',
  'en-US': 'en',
  'zh-CN': 'cn',
};
