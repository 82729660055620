import React, { useCallback } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import SVG from 'components/SVG';

import firstArrowSVG from './img/first-arrow.svg';
import lastArrowSVG from './img/last-arrow.svg';
import nextArrowSVG from './img/next-arrow.svg';
import previousSVG from './img/previous-arrow.svg';

import './Paginator.scss';

const b = block('paginator');
const Paginator = ({ count, currentPage, onPageClick, itemsOnPage }) => {
  const pagesCount = Math.ceil(count / itemsOnPage.value);
  const trueCurrentPage = currentPage;

  const getPagesSmall = useCallback(() => {
    let result = [];
    const smallCondition = pagesCount < 1 ? pagesCount : 1;
    for (let i = 0; i < smallCondition; i += 1) {
      const isActive = i === currentPage;
      const el = (
        <div key={i} className={b('page', { active: isActive })} onClick={isActive ? null : () => onPageClick(i)}>
          {i + 1}
        </div>
      );
      result.push(el);
    }
    return result;
  }, [currentPage, itemsOnPage, onPageClick, pagesCount]);

  const getPages = useCallback(() => {
    let result = [];
    const condition = trueCurrentPage + 2 > pagesCount ? pagesCount : trueCurrentPage + 2;
    for (let i = trueCurrentPage - 2; i < condition; i += 1) {
      const isActive = i === currentPage;
      const el = (
        <div key={i} className={b('page', { active: isActive })} onClick={isActive ? null : () => onPageClick(i)}>
          {i + 1}
        </div>
      );
      result.push(el);
    }
    return result;
  }, [currentPage, itemsOnPage, onPageClick, pagesCount, trueCurrentPage]);

  const pages = () => (currentPage <= 2 ? getPagesSmall() : getPages());

  const conditions = {
    isToFirstPage: trueCurrentPage !== 0,
    isToPrevPage: trueCurrentPage - 1 >= 0,
    isToNextPage: trueCurrentPage + 1 <= pagesCount - 1,
    isToLastPage: trueCurrentPage + 1 !== pagesCount && pagesCount > 0,
  };

  const goToFirstPage = () => conditions.isToFirstPage && onPageClick(0);
  const goToPrevPage = () => conditions.isToPrevPage && onPageClick(trueCurrentPage - 1);
  const goToNextPage = () => conditions.isToNextPage && onPageClick(trueCurrentPage + 1);
  const goToLast = () => conditions.isToLastPage && onPageClick(pagesCount - 1);

  return (
    <div className={b()}>
      <div className={b('main')}>
        <div className={b('page')}>
          <SVG
            onClick={() => goToFirstPage()}
            svgProps={{ svg: firstArrowSVG }}
            className={b('page', 'first-img', { active: conditions.isToFirstPage })}
          />
        </div>
        <div className={b('page')}>
          <SVG
            onClick={() => goToPrevPage()}
            svgProps={{ svg: previousSVG }}
            className={b('page', 'previous-img', { active: conditions.isToPrevPage })}
          />
        </div>
        <div className={b('pages')}>
          <div className={b('page', { active: true })}>{trueCurrentPage + 1}</div>
        </div>
        <div className={b('page')}>
          <SVG
            onClick={() => goToNextPage()}
            svgProps={{ svg: nextArrowSVG }}
            className={b('page', 'next-img', { active: conditions.isToNextPage })}
          />
        </div>
        <div className={b('page')}>
          <SVG
            onClick={() => goToLast()}
            svgProps={{ svg: lastArrowSVG }}
            className={b('page', 'last-img', { active: conditions.isToLastPage })}
          />
        </div>
      </div>
    </div>
  );
};

Paginator.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  itemsOnPage: PropTypes.number.isRequired,
  onPageClick: PropTypes.func.isRequired,
};

export default Paginator;
