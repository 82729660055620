export const initialState = {
  actionProcessing: false,
  statisticProvider: {
    providers: [],
    detail: {
      total_bet_count: 0,
      total_bet: 0,
      total_won: 0,
      total_income: 0,
    },
  },
  paymentHistory: {
    transfers: [],
    details: {
      totalCount: 0,
      totalDeposit: 0,
      totalWithdraw: 0,
      deltaBalance: 0,
    },
  },
  statisticPlayer: {
    userStatistic: [],
    details: {
      totalCount: 0,
    },
  },
  slotsHistory: {
    data: [],
    totalCount: 0,
  },
  betsHistory: {
    data: [],
    count: 0,
  },
  betInfo: {
    provider: '',
    date: '',
    gameName: '',
    balanceBefore: '',
    balanceAfter: '',
    userId: '',
    username: '',
    operation: '',
    amount: '',
    refId: '',
  },
};
