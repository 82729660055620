import BaseApi from '../BaseApi';
import { AuthConverter } from './AuthConverter';

class AuthApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/user`;
    this.converter = new AuthConverter();
  }

  signInByEmail = (email, password) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/login/email`, { email, password });

  signIn = (login, password) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/login`, { email: login, password: password });

  checkAuth = () =>
    this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/check`, null, null, this.converter.convertUserData);

  getBalance = () =>
    this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/balance`, null, null, this.converter.convertBalance);

  logOut = () => this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/logout`);

  changePassword = (pass, code) => {
    return this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/password/restore/new`, {
      code: code,
      new_password: pass,
    });
  };

  getNotifications = () =>
    this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/notifications`, null, null, this.converter.convertNotifications);

  dropNotification = id =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/notifications`, { notification_id: id });
}

export default AuthApi;
