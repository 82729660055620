import React, { useContext, useState } from 'react';
import { block } from 'bem-cn';

import { ModalContext } from '../ModalProvider';
import SVG from 'components/SVG';

import './Modal.scss';

import crossSVG from '../img/cross.svg';

const b = block('modal');

const Modal = ({ content, title, isMobile, withoutBG, closeFunction, isDesktop }) => {
  const { closeModal } = useContext(ModalContext);

  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    if(closeFunction) closeFunction();
    const closeTimeout = setTimeout(() => {
      closeModal();
      clearTimeout(closeTimeout);
    }, 175);
  };

  return (
    <div onClick={handleClose} className={b({ disappearing: isClosing })}>
      <div onClick={e => e.stopPropagation()} className={b('body', { disappearing: isClosing, isMobile, withoutBG, isDesktop })}>
        <div className={b('content', { isMobile })}>{content}</div>
      </div>
    </div>
  );
};

export default Modal;
