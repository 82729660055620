import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Spinner from 'components/Spinner/view/desktop';
import SVG from 'components/SVG';
import Button from 'components/Button';
import { useInformationModel } from 'features/users/hooks';

import { userTypes } from '../../../../data';

import './Information.scss';
import InformationTree from './InformationTree';

const b = block('information');

const Information = () => {
  const {
    usersList: { totalCount },
  } = useSelector(state => state.users);
  const locale = useSelector(state => state.locale.locale);
  const history = useHistory();

  const { userInfo, agentInfo, isLoaded, actionProcessing } = useInformationModel();

  if (actionProcessing) return <Spinner isLoading={actionProcessing} />;

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('top')}>
          <div className={b('header')}>{locale.information}</div>

          {isLoaded && (
            <>
              <div className={b('user-block')}>
                <div className={b('user-block-left')}>
                  <SVG svgProps={{ svg: userTypes[userInfo.role].icon }} className={b('user-block-icon')} />
                  <div className={b('user-block-info')}>
                    <span>{userInfo.username}</span>
                    <div className={b('user-block-info-type', { role: userInfo.role })}>
                      {userTypes[userInfo.role].type}
                    </div>
                  </div>
                </div>
                <div className={b('user-block-right')}>
                  <span>ID:</span>
                  <span>{userInfo.id}</span>
                </div>
                <div className={b('user-block-right')}>
                  <span>{locale.balance}:</span>
                  <span>{userInfo.balance}</span>
                </div>
              </div>

              <div className={b('block-wrapper')}>
                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.creationDate}</div>
                  <div className={b('block-value')}>{userInfo.firstVisit}</div>
                </div>
                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.modificationDate}</div>
                  <div className={b('block-value')}>{userInfo.lastVisit}</div>
                </div>
              </div>

              <div className={b('block-wrapper')}>
                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.username}</div>
                  <div className={b('block-value')}>{userInfo.username}</div>
                </div>

                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.name}</div>
                  <div className={b('block-value')}>{userInfo.name}</div>
                </div>

                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.email}</div>
                  <div className={b('block-value')}>{userInfo.email}</div>
                </div>

                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.surname}</div>
                  <div className={b('block-value')}>{userInfo.surname}</div>
                </div>

                <div className={b('block')}>
                  <div className={b('block-title')}>{locale.currency}</div>
                  <div className={b('block-value')}>{userInfo.currency}</div>
                </div>

                {userInfo.role === '6' && (
                  <div className={b('block')}>
                    <div className={b('block-title')}>{locale.playersCount}</div>
                    <div className={b('block-value')}>{totalCount}</div>
                  </div>
                )}
              </div>

              <div className={b('info')}>
                <p className={b('info-title')}>{locale.parent}</p>
                <div className={b('user-block')}>
                  <div className={b('user-block-left')}>
                    <SVG svgProps={{ svg: userTypes[6].icon }} className={b('user-block-icon')} />
                    <div className={b('user-block-info')}>
                      <span>{agentInfo.username}</span>
                      <div className={b('user-block-info-type', { role: 6 })}>{userTypes[6].type}</div>
                    </div>
                  </div>
                  <div className={b('user-block-right')}>
                    <span>ID:</span>
                    <span>{agentInfo.id}</span>
                  </div>
                  <div className={b('user-block-right')}>
                    <span>{locale.balance}:</span>
                    <span>{agentInfo.balance}</span>
                  </div>
                </div>
              </div>

              {userInfo.role !== '0' && (
                <div className={b('tree')}>
                  <p className={b('info-title')}>{locale.userTree}</p>
                  <InformationTree />
                </div>
              )}
            </>
          )}
        </div>
        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.close} size="low" />
        </div>
      </div>
    </div>
  );
};

export default Information;
