import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import { usePlayerHistoryListDesktop } from 'features/reports/hooks/useLists';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import './TableRow.scss';

const TableRow = ({ item, type = null, callback }) => {
  const b = block('table-row-pagination');
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const getList = useMemo(() => {
    switch (type) {
      case 'player-history':
        return usePlayerHistoryListDesktop;
      default:
        return () => Object.values(item);
    }
  }, [item, type]);

  const headers = Object.keys(item);

  const list = Object.values(getList(item));

  return (
    <div onClick={() => callback(item)} className={b('tr', { withPointer: Boolean(callback) })}>
      {list.map((el, index) => {
        const isHistory = headers[index] === 'status' || headers[index] === 'type';
        return (
          <div key={`${el}_${index}`} className={b('td', { status: isHistory && el })}>
            <span>{isHistory ? locale.statuses[el] : index > 1 ? formatNumberWithLimit(el) : el}</span>
          </div>
        );
      })}
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
