import { addNotify } from 'features/notify';
import dayjs from 'dayjs';

import { getErrorMessage } from 'shared/helpers/getErrorMessage';

const actionTypes = {
  SIGN_IN_SUCCESS: 'auth/SIGN_IN_SUCCESS',
  LOGOUT: 'auth/LOGOUT',
  GET_BALANCE: 'auth/GET_BALANCE',
  SET_IS_PROCESS: 'auth/SET_IS_PROCESS',
  GET_NOTIFICATIONS: 'auth/GET_NOTIFICATIONS',
  SET_AGENTS_FROM_NOTIFY: 'auth/SET_AGENTS_FROM_NOTIFY',
};

const login = (username, password) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { locale } = getState().locale;
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const response = await api.auth.signIn(username, password);
  if (response.success) {
    dispatch(checkAuth());
  } else {
    const errorMessage = getErrorMessage(locale, response);
    dispatch(addNotify(errorMessage, 'error'));
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
};

const checkAuth = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  const response = await api.auth.checkAuth();
  if (response.success) {
    dispatch({ type: actionTypes.SIGN_IN_SUCCESS, payload: response.data });
    dispatch(getBalance());
  } else {
    dispatch({ type: actionTypes.LOGOUT });
  }
};

const getBalance = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  const response = await api.auth.getBalance();
  if (response.success) {
    dispatch({ type: actionTypes.GET_BALANCE, payload: response.data });
    if (response.data.isBanned) {
      dispatch(logOut());
    }
  }
};

const logOut = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  const response = await api.auth.logOut();
  dispatch({ type: actionTypes.LOGOUT });
};

const getNotifications = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  const response = await api.auth.getNotifications();
  const { notifications, id } = getState().auth;

  if (response.success && response.data?.length > 0) {
    const isEqual = JSON.stringify(notifications) === JSON.stringify(response.data);
    if (!isEqual) {
      const dates = response.data
        .map(item => dayjs(item.date, 'DD.MM.YYYY HH:mm').toDate())
        .sort((a, b) => a.valueOf() - b.valueOf());

      const requestedBody = {
        date_from: dayjs(dates[0]).format('YYYY-MM-DD'),
        date_to: dayjs(dates[dates.length - 1])
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        page: 0,
        count: response.data.length,
      };

      const historyResponse = await api.finance.getFinanceHistory({ id, requestedBody });

      if (historyResponse.success) {
        const agentsData = historyResponse.data.transfers.reduce(
          (result, current) =>
            result.some(item => item.fromUserId === current.fromUserId)
              ? result
              : [
                  ...result,
                  {
                    fromUserId: current.fromUserId,
                    fromNick: current.fromNick,
                  },
                ],
          [],
        );
        dispatch({ type: actionTypes.SET_AGENTS_FROM_NOTIFY, payload: agentsData });
      }
    }

    dispatch({ type: actionTypes.GET_NOTIFICATIONS, payload: response.data });
  }
};

const dropNotifications = id => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { notifications } = getState().auth;

  api.auth.dropNotification(Number(id));

  const updatedItems = notifications.filter(item => item.id !== id);

  dispatch({ type: actionTypes.GET_NOTIFICATIONS, payload: [...updatedItems] });
};

export { actionTypes, login, logOut, checkAuth, getBalance, getNotifications, dropNotifications };
