import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { reformatNumber } from 'shared/helpers/formatNumber';

import { actions, selectUserInfoById } from '../redux';
import { actions as authActions } from '../../Auth';

export function useDepositModel() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currency } = useSelector(state => state.auth);
  const { actionProcessing } = useSelector(state => state.users);
  // Пользователь, которому начисляются средства
  const { userId: userIdTo } = useParams();
  const userInfoTo = useSelector(selectUserInfoById(userIdTo));
  // Агент, который начисляет средства
  const userIdFrom = useMemo(() => userInfoTo?.parentAgentId, [userInfoTo]);
  const userInfoFrom = useSelector(selectUserInfoById(userIdFrom));

  const [amount, setAmount] = useState('');
  const correctAmount = useMemo(() => reformatNumber(amount), [amount]);

  useEffect(() => {
    dispatch(actions.getUserInfo({ id: userIdTo }));
  }, [dispatch, userIdTo]);

  useEffect(() => {
    if (userIdFrom !== undefined) dispatch(actions.getUserInfo({ id: userIdFrom }));
  }, [dispatch, userIdFrom]);

  const players = useMemo(
    () => [
      {
        username: userInfoFrom?.username,
        balance: userInfoFrom?.balance,
        role: userInfoFrom?.role,
        title: 'from',
      },
      { username: userInfoTo?.username, balance: userInfoTo?.balance, role: userInfoTo?.role, title: 'to' },
    ],
    [userInfoFrom, userInfoTo],
  );

  const onSubmit = useCallback(() => {
    dispatch(
      actions.depositOrWithdrawal({
        amount: correctAmount,
        operation: 0,
        id: userIdTo,
        callback: () => {
          dispatch(actions.getUserInfo({ id: userIdTo }));
          dispatch(authActions.getBalance());
          history.push('/users/all');
        },
      }),
    );
  }, [correctAmount, dispatch, history, userIdTo]);

  const changeValue = useCallback(e => {
    setAmount(e.currentTarget.value);
  }, []);

  const handleInsertAmount = useCallback(
    plusSum => {
      const rightValue = +correctAmount + +plusSum;
      setAmount(rightValue.toString());
    },
    [correctAmount],
  );

  return {
    players,
    actionProcessing,
    currency,
    amount,
    correctAmount,
    isLoaded: Boolean(userInfoFrom) && Boolean(userInfoTo),
    onSubmit,
    changeValue,
    handleInsertAmount,
  };
}
