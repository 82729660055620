import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import block from 'bem-cn';

import Button from 'components/Button';
import Spinner from 'components/Spinner/view/desktop';
import InputMoney from 'components/InputMoney';
import SVG from 'components/SVG';

import { useDepositModel } from 'features/users/hooks';

import { amountForAutoInput, userTypes } from '../../../../data';

import './Deposit.scss';

const b = block('deposit');

const Deposit = () => {
  const history = useHistory();
  const locale = useSelector(state => state.locale.locale);

  const {
    players,
    actionProcessing,
    currency,
    amount,
    correctAmount,
    isLoaded,
    onSubmit,
    changeValue,
    handleInsertAmount,
  } = useDepositModel();

  if (actionProcessing) return <Spinner isLoading={actionProcessing} />;

  const disabled = !correctAmount;

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('top')}>
          <div className={b('header')}>{locale.deposit}</div>
          <div className={b('header', { small: true })}>{locale.transferOfFunds}</div>
          <div className={b('user-blocks')}>
            {isLoaded &&
              players.map(player => (
                <div className={b('user-block')}>
                  <div className={b('user-block-title')}>{locale[player.title]}</div>
                  <div className={b('user-block-wrapper')}>
                    <div className={b('user-block-left')}>
                      <SVG svgProps={{ svg: userTypes[player.role].icon }} className={b('user-block-icon')} />
                      <div className={b('user-block-info')}>
                        <span>{player.username}</span>
                        <div className={b('user-block-info-type', { role: player.role })}>
                          {userTypes[player.role].type}
                        </div>
                      </div>
                    </div>
                    <div className={b('user-block-right')}>
                      <span>{locale.balance}:</span>
                      <span>{player.balance}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className={b('input-wrapper')}>
            <span className={b('input-block-title')}>{locale.amount}</span>
            <div className={b('input-block')}>
              <div className={b('input')}>
                <InputMoney
                  value={amount}
                  onChange={changeValue}
                  placeholder={locale.amount}
                  validState={correctAmount > 0 ? 1 : 3}
                  name="amount"
                />
                <span className={b('input-currency')}>{currency}</span>
              </div>
              <div className={b('amount-buttons')}>
                {amountForAutoInput.map(i => (
                  <div className={b('amount-button')} onClick={() => handleInsertAmount(i)}>
                    {`$ ${i}`}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button
            color="green"
            disabled={disabled}
            callBack={onSubmit}
            type="submit"
            size="low"
            text={locale.deposit}
          />
        </div>
      </div>
    </div>
  );
};

export default Deposit;
