import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import block from 'bem-cn';

import Spinner from 'components/Spinner/view/desktop';
import Table from './Table/Table';
import { actions } from 'features/reports/redux';

import FilterBlock from './FilterBlock';

import './SportBets.scss';

const b = block('sport-bets');

const SportBets = () => {
  const dispatch = useDispatch();

  const { actionProcessing } = useSelector(state => state.reports, shallowEqual);
  const betsHistory = useSelector(state => state.reports.betsHistory, shallowEqual);

  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  const onArrowClick = id => {
    dispatch(actions.getDetailsBetHistory(id));
  };

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <FilterBlock count={count} currentPage={page} onPageClick={setPage} />
      <Table
        currentPage={page}
        onPageClick={setPage}
        onItemsOnPageChanged={onItemsOnPageChanged}
        thTdItems={betsHistory.data}
        totalCount={betsHistory.count}
        type="sport-bets"
        arrowOnClick={onArrowClick}
      />
    </div>
  );
};

export default SportBets;
