export const formatNumberWithLimit = number => {
  const value = Number(number);
  if (isNaN(value)) return number;
  const format = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return format.format(value);
};

export const reformatNumber = number => {
  return number.replaceAll('.', '').replaceAll(',', '.');
};
