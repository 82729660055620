import React, { forwardRef, useMemo } from 'react';
import block from 'bem-cn';
import DatePicker from 'react-datepicker';

import SVG from 'components/SVG';

import { formateDate } from 'shared/helpers';

import calendarSVG from './img/calendar.svg';
import arrowSVG from './img/arrow.svg';

import 'react-datepicker/dist/react-datepicker.css';

import './InputDate.scss';

const b = block('input-date-desktop');

const InputDate = ({
  dateFormat = 'yyyy-MM-dd',
  selected,
  isValid,
  onChange,
  color,
  withManualTyping = false,
  disabled = false,
  fontSize,
  withoutFormatting,
}) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={b('custom-date-input', { color })} onClick={onClick} ref={ref}>
      <SVG className={b('custom-date-input-calendar')} svgProps={{ svg: calendarSVG }} />
      <span className={b('custom-date-input-value', { fontSize })}>
        {withoutFormatting ? value : formateDate(value)}
      </span>
      {!disabled && <SVG className={b('custom-date-input-arrow')} svgProps={{ svg: arrowSVG }} />}
    </div>
  ));

  const validState = useMemo(() => {
    if (String(selected).length === 0 || isValid === undefined) return 1;
    if (isValid) return 2;
    return 3;
  }, [isValid, selected]);

  return (
    <div className={b({ validState, withManualTyping, disabled })}>
      {withManualTyping ? (
        <div className={b('custom-date-input', { color })}>
          <SVG className={b('custom-date-input-calendar')} svgProps={{ svg: calendarSVG }} />
          <DatePicker disabled={disabled} selected={selected} onChange={onChange} dateFormat={dateFormat} />
          {!disabled && <SVG className={b('custom-date-input-arrow')} svgProps={{ svg: arrowSVG }} />}
        </div>
      ) : (
        <DatePicker
          dateFormat={dateFormat}
          selected={selected}
          onChange={onChange}
          disabled={disabled}
          customInput={<ExampleCustomInput />}
        />
      )}
    </div>
  );
};

export default InputDate;
